import { USStateMapping } from '@rategravity/core-lib/enums';
import {
  AdornOwnUpInput,
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpCheckGroup,
  OwnUpDropDown,
  OwnUpPhoneInput,
  OwnUpRadioGroup,
  OwnUpTextArea,
  OwnUpTextInput
} from '@rategravity/own-up-component-library';
import React, { useRef, useState } from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { CallToActionButton, WrapperButton } from '../../components/button';
import { HorizontalLine } from '../../components/horizontal-line';
import { Layout } from '../../components/layout';
import {
  ErrorText,
  PrimaryText,
  PrimaryTextMixin,
  ResponsivePrimaryText,
  ResponsiveSectionHeader
} from '../../components/typography';
import { validateForm } from '../../modules/review-utils';

const USStates = USStateMapping.map(({ name }) => name);

const AsteriskComponent = styled('span')`
  color: 'red';
`;

const Asterisk = () => <AsteriskComponent aria-hidden="true">*</AsteriskComponent>;

const FormStyle = createOwnUpStyle({
  padding: '30px 0 60px'
});

const Form = createOwnUpComponent('form', FormStyle);

const FormFieldWrapperStyle = createOwnUpStyle({
  paddingBottom: '15px',
  '& textarea': {
    width: 'unset !important'
  },
  '& div[class*="ou-input-adornment-container"]': {
    minWidth: '220px',
    maxWidth: 'unset'
  }
});

const FormFieldWrapper = createOwnUpComponent('div', FormFieldWrapperStyle);

const GroupWrapperStyle = createOwnUpStyle({
  paddingTop: '30px',
  '#requestType': {
    display: 'flex',
    flexDirection: 'column'
  },
  '#responsePreference': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& label': {
    width: 'unset',
    minWidth: 'unset'
  }
});

const GroupWrapper = createOwnUpComponent('div', GroupWrapperStyle);

const DoubleWrapperStyle = createOwnUpStyle({
  display: 'flex',
  width: '100%',
  variants: {
    smallAndDown: {
      flexDirection: 'column'
    }
  }
});

const DoubleWrapper = createOwnUpComponent('div', DoubleWrapperStyle);

const LeftWrapperStyle = createOwnUpStyle({
  width: '50%',
  paddingRight: '20px',
  variants: {
    smallAndDown: {
      width: '100%',
      paddingRight: 0
    }
  }
});

const LeftWrapper = createOwnUpComponent('div', LeftWrapperStyle);

const RightWrapperStyle = createOwnUpStyle({
  width: '50%',
  paddingLeft: '20px',
  variants: {
    smallAndDown: {
      width: '100%',
      paddingLeft: 0
    }
  }
});

const RightWrapper = createOwnUpComponent('div', RightWrapperStyle);

const Label = styled('label')`
  ${PrimaryTextMixin}
  font-size: 16px;
`;

const ButtonWrapper = createOwnUpComponent('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '60px 0'
});

const requestTypeOptions = [
  'The categories of personal information Own Up has collected about you',
  'The categories of sources from which the personal information is collected',
  'The business or commercial purpose for collecting your personal information',
  'The categories of third parties with whom Own Up shares personal information',
  'The specific pieces of personal information Own Up has collected about you',
  'The categories of personal information that Own Up sold about you, if any, and the categories of third parties to whom the personal information was sold, if any',
  'The categories of personal information that Own Up disclosed about you for a business purpose',
  'Request to Delete: My personal information collected by Own Up in the last 12 months, subject to any relevant exceptions provided in the CCPA'
];

const errorText: Record<string, string> = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email address',
  phone: 'Phone number',
  requestType: 'Request type',
  responsePreference: 'Response preference',
  address:
    'Address (street, city, state, and postal code are required if response preference is US Mail)'
};

// eslint-disable-next-line max-lines-per-function, abcsize/abcsize
const CCPARequestForm = () => {
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [street, setStreet] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [USState, setUSState] = useState<typeof USStates[number]>('California');
  const [postalCode, setPostalCode] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<number | undefined>(undefined);
  const [mobile, setMobile] = useState<number | undefined>(undefined);
  const [loanNumber, setLoanNumber] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [requestType, setRequestType] = useState<string[]>([]);
  const [responsePreference, setResponsePreference] = useState<string | undefined>(undefined);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const errorRef = useRef<AnyStyledComponent>(null);
  const successRef = useRef<AnyStyledComponent>(null);

  const Feedback = () => (
    <>
      {formErrors.length > 0 ? (
        <ErrorText
          ref={errorRef}
          tabIndex={0}
        >{`Please fill out the required fields: ${formErrors.map(
          (error) => ` ${errorText[error]}`
        )}`}</ErrorText>
      ) : null}
      {submitted ? (
        <PrimaryText ref={successRef} tabIndex={0}>
          Thank you for submitting the CCPA Electronic Request form. We will be in touch with you
          soon.
        </PrimaryText>
      ) : null}
    </>
  );

  // eslint-disable-next-line max-lines-per-function, abcsize/abcsize
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors = validateForm({
      firstName,
      lastName,
      email,
      phone,
      requestType,
      responsePreference,
      // if mail was selected as the response preference but address is missing, add an error
      ...(responsePreference === 'US Mail'
        ? { address: street && city && USState && postalCode }
        : {})
    });
    setFormErrors(errors);

    const focusToError = () => errorRef && errorRef.current && errorRef.current.base.focus();

    const focusToSuccess = () =>
      successRef && successRef.current && successRef.current.base.focus();

    if (errors.length < 1) {
      const url = '/privacy/ccpa-request/hubspot/submit-kickout/';
      const hubspotFormId = '64be4057-11e3-4b7b-8086-8fb4fa5ffa45';
      const payload = {
        formId: hubspotFormId,
        data: {
          firstname: firstName,
          lastname: lastName,
          email,
          address: street,
          city,
          state: USState,
          zip: postalCode,
          // Hubspot expects strings for phone and mobile.
          phone: phone?.toString(),
          mobilephone: mobile?.toString(),
          /* eslint-disable @typescript-eslint/naming-convention */
          ccpa_loan_number: loanNumber,
          ccpa_message: message,
          // checkbox properties in Hubspot expect a string with the values separated by a semicolon.
          // https://legacydocs.hubspot.com/docs/faq/how-do-i-set-multiple-values-for-checkbox-properties
          ccpa_request_type: requestType.reduce((acc, curr) => `${acc};${curr}`, '').slice(1),
          ccpa_response_channel: responsePreference
          /* eslint-enable @typescript-eslint/naming-convention */
        }
      };
      await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      setSubmitted(true);
      setTimeout(focusToSuccess, 500);
    } else {
      setSubmitted(false);
      setTimeout(focusToError, 200);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <DoubleWrapper>
        <LeftWrapper>
          <FormFieldWrapper>
            <Label htmlFor="firstName">
              First name <Asterisk />
            </Label>
            <AdornOwnUpInput
              aria-required="true"
              input={OwnUpTextInput}
              id="firstName"
              name="First name"
              value={firstName}
              onChange={(next) => setFirstName(next)}
            />
          </FormFieldWrapper>
        </LeftWrapper>
        <RightWrapper>
          <FormFieldWrapper>
            <Label htmlFor="lastName">
              Last name <Asterisk />
            </Label>
            <AdornOwnUpInput
              aria-required="true"
              input={OwnUpTextInput}
              id="lastName"
              name="Last name"
              value={lastName}
              onChange={(next) => setLastName(next)}
            />
          </FormFieldWrapper>
        </RightWrapper>
      </DoubleWrapper>
      <FormFieldWrapper>
        <Label htmlFor="email">
          Email address <Asterisk />
        </Label>
        <AdornOwnUpInput
          aria-required="true"
          input={OwnUpTextInput}
          id="email"
          name="Email address"
          value={email}
          onChange={(next) => setEmail(next)}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Label htmlFor="street">Street</Label>
        <AdornOwnUpInput
          input={OwnUpTextInput}
          id="street"
          name="Street"
          value={street}
          onChange={(next) => setStreet(next)}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Label htmlFor="city">City</Label>
        <AdornOwnUpInput
          input={OwnUpTextInput}
          id="city"
          name="City"
          value={city}
          onChange={(next) => setCity(next)}
        />
      </FormFieldWrapper>
      <DoubleWrapper>
        <LeftWrapper>
          <FormFieldWrapper>
            <Label htmlFor="state">State</Label>
            <AdornOwnUpInput
              id="state"
              name="State"
              input={OwnUpDropDown}
              value={USState}
              onChange={(next) => setUSState(next)}
              inputProps={{ options: USStates, variant: 'onboarding' }}
            />
          </FormFieldWrapper>
        </LeftWrapper>
        <RightWrapper>
          <FormFieldWrapper>
            <Label htmlFor="postalCode">Postal code</Label>
            <AdornOwnUpInput
              input={OwnUpTextInput}
              id="postalCode"
              name="Postal code"
              value={postalCode}
              onChange={(next) => setPostalCode(next)}
            />
          </FormFieldWrapper>
        </RightWrapper>
      </DoubleWrapper>
      <DoubleWrapper>
        <LeftWrapper>
          <FormFieldWrapper>
            <Label htmlFor="phone">
              Phone number <Asterisk />
            </Label>
            <AdornOwnUpInput
              aria-required="true"
              input={OwnUpPhoneInput}
              id="phone"
              name="Phone number"
              value={phone}
              onChange={(next) => setPhone(next)}
            />
          </FormFieldWrapper>
        </LeftWrapper>
        <RightWrapper>
          <FormFieldWrapper>
            <Label htmlFor="mobile">Mobile number</Label>
            <AdornOwnUpInput
              input={OwnUpPhoneInput}
              id="mobile"
              name="Mobile number"
              value={mobile}
              onChange={(next) => setMobile(next)}
            />
          </FormFieldWrapper>
        </RightWrapper>
      </DoubleWrapper>
      <FormFieldWrapper>
        <Label htmlFor="loanNumber">Loan number</Label>
        <AdornOwnUpInput
          input={OwnUpTextInput}
          id="loanNumber"
          name="Loan number"
          value={loanNumber}
          onChange={(next) => setLoanNumber(next)}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Label htmlFor="message">Message</Label>
        <AdornOwnUpInput
          input={OwnUpTextArea}
          id="message"
          name="Message"
          value={message}
          onChange={(next) => setMessage(next)}
        />
      </FormFieldWrapper>
      <GroupWrapper style={{ marginTop: '100px' }}>
        <Label htmlFor="requestType">
          Request type <Asterisk />
        </Label>
        <OwnUpCheckGroup
          aria-required="true"
          aria-label="Checkbox group"
          options={requestTypeOptions}
          id="requestType"
          value={requestType}
          onChange={(next) => setRequestType(next)}
        />
      </GroupWrapper>
      <GroupWrapper>
        <Label htmlFor="responsePreference">
          How would you like to receive a response? <Asterisk />
        </Label>
        <OwnUpRadioGroup
          aria-required="true"
          id="responsePreference"
          groupName={'Radio group'}
          value={responsePreference}
          onChange={(next) => setResponsePreference(next)}
          options={['Email', 'US Mail']}
        />
      </GroupWrapper>
      <ButtonWrapper>
        <WrapperButton onClick={handleSubmit}>
          <CallToActionButton>Submit</CallToActionButton>
        </WrapperButton>
      </ButtonWrapper>
      <Feedback />
    </Form>
  );
};

export const CCPARequest = () => (
  <Layout>
    <div style={{ margin: '0 30px' }}>
      <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
        <ResponsiveSectionHeader variant="title">
          CCPA Electronic Request Form
        </ResponsiveSectionHeader>
        <ResponsivePrimaryText>For California residents only</ResponsivePrimaryText>
      </div>
      <HorizontalLine aria-hidden="true" />
      <CCPARequestForm />
    </div>
  </Layout>
);

export default CCPARequest;
