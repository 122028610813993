import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import { colors } from '../../modules/colors';

const HorizontalLineStyle = createOwnUpStyle({
  border: `0 none`,
  height: 1,
  backgroundColor: colors.GREY_LINE,
  variants: {
    header: {
      margin: '50px 0px 30px',
      width: '90vw'
    },
    footer: {
      margin: '20px 0px 40px',
      width: '50vw'
    },
    faqShort: {
      backgroundColor: colors.ALT_LIGHT_GREY,
      width: 154,
      marginLeft: 30
    },
    faqLong: {
      backgroundColor: colors.LOGO_BACKDROP,
      marginBottom: 50,
      marginTop: 50
    }
  }
});

export const HorizontalLine = createOwnUpComponent('hr', HorizontalLineStyle);
